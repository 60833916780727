import React, { useContext } from 'react'
import { CardDiscount, DisabledLabel, DiscountWrapper, ImageLabelsContainer } from 'chemistry'

import { TranslateContext } from 'client/common/translator'

export const CardImageLabel = ({ disabledKey, discountProps, deliveryModeTranslationKey }) => {
  const t = useContext(TranslateContext)

  if (disabledKey) return <DisabledLabel text={t(disabledKey)} />

  return (
    <ImageLabelsContainer>
      {discountProps.showDiscount && (
        <DiscountWrapper>
          <CardDiscount {...discountProps} />
        </DiscountWrapper>
      )}
    </ImageLabelsContainer>
  )
}
