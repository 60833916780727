import React, { useContext, useEffect, useState } from 'react'
import {
  CardImage,
  CardVlp,
  CheckboxAtom,
  ChevronDown,
  getInnerComponent,
  highestPriorityPromo,
  Link,
  ProductCard,
  ProductStatusLabel
} from 'chemistry'
import { CardPrice } from 'client/common/components/Card/CardPrice'
import { Text } from '@chakra-ui/react'
import { CardImageLabel } from 'client/common/components/Card/CardImageLabel'
import { TranslateContext } from 'client/common/translator'
import { extractImageProps } from 'client/common/components/Card/imageProps'
import { extractPriceProps } from 'client/common/components/Card/priceProps'
import { extractDiscountProps } from 'client/common/components/Card/discountProps'
import { getDeliveryModeText } from 'client/common/components/Card/deliveryModesProps'
import { CardSchema } from 'client/fragments/listerFragment/components/SchemaOrg/CardSchema'
import { statusColorMapping } from 'client/common/components/Card/cardStatusColor'
import { RatingStars } from 'client/common/components/Card/RatingStars'
import { createLogger } from 'common/log'

const DISABLED_STATUSES = ['Binnenkort verkrijgbaar', 'Bientôt disponible']
const logger = createLogger('lister/Card')

export const Card = props => {
  const t = useContext(TranslateContext)
  const [shouldBeBVRatingShown, setShouldBeBVRatingShown] = useState(false)
  const {
    sizes = ['sm', 'md', 'lg'],
    fullPriceProps,
    disableStatus,
    deliveryModes,
    t: translations,
    format,
    lang,
    categoryCode,
    availabilityStatus,
    isChecked,
    onProductCheck,
    target,
    showBVRatingOnListerCards,
    withArrow = false
  } = props
  const hasPriceError = !!fullPriceProps?.errors?.length
  if (hasPriceError) {
    logger.error(`PRICE_ERROR, product ${format} ${props?.id} has corrupted price on lister`)
  }

  const imageProps = extractImageProps(props)
  const winnerPromo = highestPriorityPromo(props)
  const priceComponentProps = extractPriceProps({ ...fullPriceProps })
  const promotionType = fullPriceProps.type
  const discountProps = extractDiscountProps(props, promotionType, winnerPromo)
  const deliveryModeTranslationKey = getDeliveryModeText(deliveryModes, t)
  const disabledKey = disableStatus?.isDisabled && translations.temporarilyDisabled
  const isDisabledStatus = DISABLED_STATUSES.includes(availabilityStatus)

  useEffect(() => {
    // temporary, while testing on acc, do not show on production for Brico
    setShouldBeBVRatingShown(window?.location?.host !== 'www.brico.be' && showBVRatingOnListerCards)
  }, [showBVRatingOnListerCards])

  return (
    <>
      {!categoryCode && <CardSchema {...props} />}
      <ProductCard
        sizes={sizes}
        link={props.link}
        target={target}
        disabled={disabledKey || isDisabledStatus || (onProductCheck && !isChecked)}
      >
        {onProductCheck && <CheckboxAtom isChecked={isChecked} onChange={() => onProductCheck(props.id)} />}
        <ProductCard.Image>
          <div className='relative'>
            <CardImage {...imageProps} />
            {!hasPriceError && (
              <CardImageLabel
                disabledKey={disabledKey}
                discountProps={discountProps}
                deliveryModeTranslationKey={deliveryModeTranslationKey}
              />
            )}
          </div>
        </ProductCard.Image>

        <ProductCard.Title>
          <Text fontSize='sm' fontFamily='bold' wordBreak='break-word' data-testid='card-title'>
            {props?.title}
          </Text>
        </ProductCard.Title>

        <ProductCard.Extra>
          {shouldBeBVRatingShown && (
            <RatingStars
              rating={props?.reviews?.rating || 0}
              label={`(${props?.reviews?.count || '0'})`}
              productId={props?.id}
            />
          )}
          {availabilityStatus || disabledKey ? (
            <ProductStatusLabel
              disabledKey={disabledKey}
              availabilityStatus={availabilityStatus}
              color={statusColorMapping(availabilityStatus, disabledKey)}
            />
          ) : (
            <Text>{props?.category?.title}</Text>
          )}
        </ProductCard.Extra>

        {fullPriceProps.isVLP && <CardVlp>{getInnerComponent(format, lang, translations.vlp)}</CardVlp>}
        {!disabledKey && <CardPrice {...priceComponentProps} promotionType={promotionType} />}
        {withArrow && (
          <Link variant='primary' target={target}>
            <ChevronDown color='white' />
          </Link>
        )}
      </ProductCard>
    </>
  )
}
