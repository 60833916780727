const ONLINE_ONLY_REGEXP = /^SHD/
const SHIPPING_REGEXP = /^SHD|^(\d+|VIRTUAL)(-[^-]+)?$/
const CUSTOMORDER_REGEXP = /^CUSTOMORDER(.*)/
const VIRTUAL_REGEXP = /^(\d+|VIRTUAL)(-[^-]+)?$/
const PICKUP_REGEXP = /^PICKUP/

const getDeliveryModeType = (deliveryModes = []) => {
  const codes = [...new Set(deliveryModes.filter(mode => !!mode?.code).map(mode => mode.code))]

  if (codes.every(ONLINE_ONLY_REGEXP.test, ONLINE_ONLY_REGEXP)) return 'online_only'
  if (codes.some(CUSTOMORDER_REGEXP.test, CUSTOMORDER_REGEXP)) {
    if (codes.some(VIRTUAL_REGEXP.test, VIRTUAL_REGEXP)) return 'on_order'
    if (codes.some(PICKUP_REGEXP.test, PICKUP_REGEXP)) return 'in_store'
  }
  if (codes.every(SHIPPING_REGEXP.test, SHIPPING_REGEXP)) return 'online_only_old'

  return null
}

export const getDeliveryModeText = (deliveryModes, t) => {
  const type = getDeliveryModeType(deliveryModes)
  return type && t(`lister.products.deliveryMode.${type}`)
}
