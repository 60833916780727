import React from 'react'
import { formatPrice, Price, PriceAdditionalInfo, PriceInfoContainer, PriceLabel } from 'chemistry'

export const CardPrice = props => {
  const {
    oldUnitPrice,
    oldPrice,
    unitPrice,
    price,
    packetText,
    units,
    packagePriceFirst,
    showPriceLabel,
    labelText,
    percentage,
    icons
  } = props

  let crossedPrice = oldUnitPrice || oldPrice
  let bigPrice = unitPrice || price || crossedPrice
  let priceAdditionalInfo = `${packetText} `
  let unitsStr = units
  let additionalInfoPrice = price || oldPrice

  if (packagePriceFirst !== false) {
    crossedPrice = oldPrice
    bigPrice = price || crossedPrice
    priceAdditionalInfo = `${units} `
    unitsStr = null
    additionalInfoPrice = unitPrice || oldUnitPrice
  }

  if (!price) return null

  return (
    <PriceInfoContainer>
      <PriceInfoContainer.Before>
        {showPriceLabel && <PriceLabel text={labelText} percentage={percentage} />}
      </PriceInfoContainer.Before>
      <PriceInfoContainer.Main>
        <Price oldPrice={crossedPrice} price={bigPrice} units={unitsStr}>
          {unitPrice && (
            <PriceAdditionalInfo text={priceAdditionalInfo}>
              <b>{formatPrice(additionalInfoPrice)}</b>
            </PriceAdditionalInfo>
          )}
        </Price>
        {!!icons.length && (
          <div className='flex flex-auto items-center justify-end gap-half'>
            {icons.map((src, i) => (
              <img key={i} src={src} style={{ height: '2em', width: '2em' }} alt='icon' />
            ))}
          </div>
        )}
      </PriceInfoContainer.Main>
    </PriceInfoContainer>
  )
}
